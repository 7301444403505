/* Login.css */
body {
    font-family: 'sans-serif'; /* Replace 'Your Chosen Font' with the desired font name */
  }

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
  }
  
  .login-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .login-table {
    border-collapse: collapse;
    width: 400px; /* Set the width of the table as needed */
  }
  
  .login-table td {
    padding: 10px;
    /* border: 1px solid #ddd; */
  }
  
  .login-table label {
    font-weight: bold;
  }
  
  .welcome-back {
    font-weight: bold;
    font-size: 20px;
  }

  .instruction {
    font-size: 18px;
    color: #888;
  }
  .login-table input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .login-table button {
    width: 100%;
    padding: 10px;
    background-color: black;
    color: white;
    border: none;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
  }
  
