.header-container {
  background-color: #3498db;
  color: #fff;
  padding: 30px;
  height: 20%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-row h1 {
  margin: 0;
}

.home-button,
.logout-button {
  padding: 10px;
  background-color: transparent;
  border: 1px solid #fff;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.home-button:hover,
.logout-button:hover {
  background-color: #fff;
  color: #3498db;
}

.logout-button {
  margin-left: auto; /* Push the button to the right */
}

