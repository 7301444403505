.footer-container {
    background-color: #3498db;
    color: #fff;
    padding: 20px;
    height: 25%;
    box-sizing: border-box; /* Include padding in height calculation */
  }
  .footer-row {
    display: flex;
    justify-content: space-between;
  }