/* Home.css */
.home-container {
    text-align: center;
    padding: 20px;
    height: 80%;
    box-sizing: border-box; /* Include padding in height calculation */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .button-container {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
  align-items: center;
  margin: 15%;
   
  }
  
  .button-container a {
    text-decoration: none;
  }
  
  .button-container button {
    width: 150px; /* Set a consistent width for all buttons */
    padding: 10px;
    border: none;
    cursor: pointer;
    
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add box shadow */
  }
  
  .sales-button {
    background-color: #3498db;
    color: #fff;
  }
  
  .services-button {
    background-color: #e74c3c;
    color: #fff;
  }
  
  .combined-button {
    background-color: #2ecc71;
    color: #fff;
  }
  .new-button {
    background-color: #580f4c;
    color: #fff;
  }  
